import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCookie } from "../../components/utils/cookies";
import { Title } from "../../components/atoms/Title/title";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import FaqItem from "../../components/molecules/FaqItem/FaqItem";
import { Helmet } from "react-helmet";
import QuestionMarkIcon from "../../images/icons/help_outline_24px.svg";
import SiteDataContext from "../../context/SiteDataContext";

import "./faq.scss";

const parseTitle = (title, metaData) => {
  let parsedTitle = title;

  Object.keys(metaData).forEach((key) => {
    const pattern = `{${key}}`;
    const rgx = new RegExp(pattern, "g");
    parsedTitle = title.replace(rgx, metaData[key]);
  });

  return parsedTitle;
};

const FaqList = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulFaq {
        slug
        title
        faqItem {
          title
          content {
            raw
          }
        }
      }
    }
  `);

  const { domain } = getCookie("site_info");
  const metaData = { web_name: domain };
  const sitedata = useContext(SiteDataContext);

  return (
    <Layout>
      <Helmet>
        <title>{data.contentfulFaq.title}</title>
        <meta
          name="description"
          content=""
        />
      </Helmet>
      <Wrapper>
        <div className="faq">
          <Title
            hero
            tag="h1"
            content={`Frequently Asked Questions`}
            icon={QuestionMarkIcon}
          />
          {data.contentfulFaq.faqItem.map((item, id) => (
            <FaqItem
              title={parseTitle(item.title, metaData)}
              key={`faq-${id}`}
              content={item.content}
              sitedata={sitedata}
            />
          ))}
        </div>
      </Wrapper>
    </Layout>
  );
};

export default FaqList;
